import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ImageLink from 'components/Image/ImageLink';
import Sources from 'components/Image/Sources';

import { getImageAltTextAndUrl } from 'analytics/aem/get-image-alt-text-and-url';

import env from 'env/env';

import { SHOP_WINDOWS_NAME } from 'components/AemComponent/analyticsConstants';
import styles from './index.scss';

const buildImageUrl = src => `${src.startsWith('/') ? env.cfDomain : ''}${src}`;

const ShopWindows = ({ analytics = {}, analyticsTags, items, position, preloadImages }) => {
  if (!items || items.length < 1) return null;

  return (
    <nav
      data-test="shop-windows"
      role="navigation"
      className={styles.shopWindowWrapper}
      {...analyticsTags}
    >
      {items.map(({ link, image }, index) => {
        const { newWindow, text, url } = link;
        const { dynamicImages, src } = image;

        if (!src) return null;

        const imageUrl = buildImageUrl(src);
        const windowId = `ShopWindows-${index}`;
        // If the link text is 'offers' then a custom CSS class will be applied to make the link text red on large screens.
        const isOffers = text.toLowerCase() === 'offers';

        const linkProps = {
          analytics,
          componentId: windowId,
          linkURL: url,
          newWindow,
          position,
          getImageAltTextAndUrl: () =>
            getImageAltTextAndUrl({
              altText: text,
              fileReference: image.fileReference,
            }),
        };

        return (
          <span key={windowId} className={styles.shopWindow}>
            <ImageLink
              className={classnames({
                [styles.shopWindowOffers]: isOffers,
              })}
              {...linkProps}
            >
              <picture>
                <Sources
                  scene7Url={
                    imageUrl?.includes('.png') && dynamicImages?.highQuality95
                      ? `${dynamicImages?.highQuality95}&fmt=png-alpha`
                      : dynamicImages?.highQuality95
                  }
                  sizes="(max-width: 544px) 50vw, (max-width: 991px) 25vw, 1px"
                  preload={preloadImages}
                />
                <img
                  alt={text || ''}
                  loading={preloadImages ? undefined : 'lazy'}
                  // eslint-disable-next-line react/no-unknown-property
                  fetchPriority={preloadImages ? 'high' : undefined}
                  src={imageUrl}
                  width={800}
                  height={800}
                />
              </picture>
              <span className={styles.shopWindowText}>{text}</span>
            </ImageLink>
          </span>
        );
      })}
    </nav>
  );
};

ShopWindows.displayName = SHOP_WINDOWS_NAME;

ShopWindows.propTypes = {
  analytics: PropTypes.shape({ promoName: PropTypes.string }),
  analyticsTags: PropTypes.shape({}),
  componentId: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        fileReference: PropTypes.string,
        dynamicImages: PropTypes.shape({ highQuality95: PropTypes.string }),
      }),
      link: PropTypes.shape({
        text: PropTypes.string,
      }),
    }),
  ),
  position: PropTypes.string,
  preloadImages: PropTypes.bool,
};

ShopWindows.defaultProps = {
  analytics: undefined,
  analyticsTags: undefined,
  componentId: undefined,
  items: undefined,
  position: undefined,
  preloadImages: false,
};

export default ShopWindows;
